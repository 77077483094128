export const notes = [
  {
    center: [31.451, 126.801],
    labelPos: [67.357, 129.758],
    name: 'G5',
    pitch: 79,
    rect: [2.77, 96.913, 60.651, 60.505],
  },
  {
    center: [91.559, 331.83],
    labelPos: [131.799, 323.082],
    name: 'G4',
    pitch: 67,
    rect: [64.463, 303.267, 77.298, 56.165],
  },
  {
    center: [34.075, 360.365],
    labelPos: [67.999, 355.49],
    name: 'F#4',
    pitch: 66,
    rect: [2.0, 330.664, 62.4, 56.277],
  },
  {
    center: [91.956, 157.563],
    labelPos: [132.965, 160.375],
    name: 'F#5',
    pitch: 78,
    rect: [63.588, 127.93, 77.881, 59.565],
  },
  {
    center: [91.997, 274.387],
    labelPos: [130.049, 266.514],
    name: 'B4',
    pitch: 71,
    rect: [63.673, 245.862, 77.298, 57.865],
  },
  {
    center: [31.159, 66.15],
    labelPos: [66.483, 70.565],
    name: 'B5',
    pitch: 83,
    rect: [2.624, 19.35, 60.942, 77.709],
  },
  {
    center: [91.81, 97.058],
    labelPos: [131.216, 100.89],
    name: 'A5',
    pitch: 81,
    rect: [63.573, 53.889, 78.244, 74.21],
  },
  {
    center: [32.909, 302.776],
    labelPos: [67.332, 301.938],
    name: 'A4',
    pitch: 69,
    rect: [2.0, 274.095, 62.984, 56.714],
  },
  {
    center: [32.325, 186.577],
    labelPos: [67.066, 187.201],
    name: 'E5',
    pitch: 76,
    rect: [3.062, 156.98, 62.984, 59.193],
  },
  {
    center: [90.976, 388.69],
    labelPos: [132.382, 377.901],
    name: 'E4',
    pitch: 64,
    rect: [63.211, 358.56, 78.173, 73.485],
  },
  {
    center: [34.367, 417.516],
    labelPos: [69.982, 408.81],
    name: 'D4',
    pitch: 62,
    rect: [1.75, 386.94, 61.817, 66.774],
  },
  {
    center: [92.393, 216.36],
    labelPos: [132.965, 216.36],
    name: 'D5',
    pitch: 74,
    rect: [65.921, 187.497, 75.257, 58.448],
  },
  {
    center: [32.617, 245.624],
    labelPos: [67.941, 245.56],
    name: 'C5',
    pitch: 72,
    rect: [2.583, 215.882, 63.567, 58.318],
  },
];

export type INoteInfo = typeof notes[0];
